import device from "src/constants/media";
import styled from "styled-components";

export const TextContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  text-align: center;
  place-items: center;
`;
export const IntegrationsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 8px;
  place-content: center;
  place-items: center;
  width: 100%;
`;
export const IntegrationLogo = styled.div`
  width: 96px;
  height: 64px;
  grid-column: span 3;
  @media ${device.mobileL} {
    grid-column: span 2;
  }
  @media ${device.laptop} {
    grid-column: span 1;
  }
`;
