import Image from "next/image";
import React from "react";
import { SectionCard } from "src/components/ui/common";
import { colors } from "src/theme";
import { Body, LargeTitle, Title } from "src/theme/typography";
import { TextContainer, IntegrationsContainer, IntegrationLogo } from "./Integrations.styles";

const data = [
  {
    image: { url: "/integrations/advantage.png" },
    id: "advantage",
    name: "Advantage+",
  },
  {
    image: { url: "/integrations/altapoint.png" },
    id: "altapoint",
    name: "AltaPoint",
  },
  // {
  //   image: { url: "/integrations/animana.png" },
  //   id: "animana",
  //   name: "IDEXX Animana",
  // },
  {
    image: { url: "/integrations/avimark.png" },
    id: "avimark",
    name: "AVIMark",
  },
  // {
  //   image: { url: "/integrations/cornerstone.png" },
  //   id: "cornerstone",
  //   name: "IDEXX Cornerstone",
  // },
  {
    image: { url: "/integrations/dvm-manager.png" },
    id: "dvm-manager",
    name: "DVM Manager",
  },
  // {
  //   image: { url: "/integrations/ezyvet.png" },
  //   id: "ezyvet",
  //   name: "ezyVet",
  // },
  {
    image: { url: "/integrations/ezofficesystems.png" },
    id: "ezofficesystems",
    name: "ezvetpro by ezofficesystems",
  },
  {
    image: { url: "/integrations/impromed.png" },
    id: "impromed",
    name: "Impromed",
  },
  {
    image: { url: "/integrations/intravet.png" },
    id: "intravet",
    name: "Intravet",
  },
  {
    image: { url: "/integrations/merlin.png" },
    id: "merlin",
    name: "Merlin",
  },
  {
    image: { url: "/integrations/teleos.png" },
    id: "teleos",
    name: "Teleos",
  },
  {
    image: { url: "/integrations/rxworks.png" },
    id: "rxworks",
    name: "RxWorks",
  },
  {
    image: { url: "/integrations/robovet.png" },
    id: "robovet",
    name: "Robovet",
  },
  {
    image: { url: "/integrations/via.png" },
    id: "via",
    name: "VIA",
  },
  // {
  //   image: { url: "/integrations/vtp.png" },
  //   id: "vtp",
  //   name: "2i Nova V-Tech Platinum (VTP)",
  // },
];

const Integrations = () => {
  return (
    <SectionCard>
      <TextContainer>
        <Title textColor={colors.grey}>How we&apos;re smarter&hellip;</Title>
        <LargeTitle as="p" style={{ maxWidth: 640 }}>
          PetsApp and your PMS &mdash; in-sync{" "}
          <span style={{ color: colors.main }}>with integrations</span>
        </LargeTitle>
        <Body>
          Pet and client information, reminders, and appointments all automatically sync with your
          PMS. You can contact every client in your PMS via PetsApp from day 1.
        </Body>
      </TextContainer>

      <IntegrationsContainer>
        {data.map(({ image, id, name }) => (
          <IntegrationLogo key={id}>
            <Image src={image.url} width={96} height={64} layout="intrinsic" key={id} alt={name} />
          </IntegrationLogo>
        ))}
      </IntegrationsContainer>
      <Title>&hellip;and more!</Title>
    </SectionCard>
  );
};

export default Integrations;
